import { IIconProps } from "./types";

export const LogoIcon: React.FC<IIconProps> = ({ size = 32 }) => {
  return (
    <svg
      width={size}
      height={size / 1.23}
      viewBox="0 0 938 762"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M839.854 527.329C893.784 527.329 937.5 483.613 937.5 429.712C937.5 375.753 893.784 332.036 839.854 332.036H800.771C800.771 148.66 652.141 0 468.75 0C285.359 0 136.729 148.66 136.729 332.036H97.6465C43.7164 332.036 0 375.753 0 429.712C0 483.615 43.7164 527.329 97.6465 527.329H201.817L242.902 588.955L103.455 728.403C95.8154 736.013 95.8154 748.372 103.455 756.011C111.064 763.651 123.453 763.651 131.063 756.011L281.611 605.493H449.209V742.192C449.209 747.171 451.126 752.206 454.931 756.011C462.57 763.651 474.93 763.651 482.569 756.011C486.374 752.206 488.291 747.171 488.291 742.192V605.493H655.975L806.523 756.011C814.133 763.651 826.492 763.651 834.131 756.011C841.771 748.372 841.771 736.013 834.131 728.403L694.656 588.898L735.683 527.329H839.854ZM198.783 218.011C213.545 183.135 234.661 151.793 261.583 124.871C288.505 97.9633 319.832 76.8347 354.708 62.0709C390.817 46.8072 429.183 39.0674 468.75 39.0674C508.317 39.0674 546.683 46.8072 582.79 62.0691C617.666 76.8311 648.994 97.9614 675.916 124.869C702.838 151.791 723.951 183.133 738.715 218.009C753.966 254.103 761.719 292.469 761.719 332.036H723.552C723.552 191.316 609.456 77.2485 468.75 77.2485C457.963 77.2485 449.209 85.9882 449.209 96.7749C449.209 107.562 457.963 116.301 468.75 116.301C526.371 116.301 580.558 138.746 621.299 179.487C662.04 220.243 684.47 274.417 684.47 332.036H175.781C175.781 292.469 183.506 254.103 198.783 218.011ZM97.6465 488.306C65.3448 488.306 39.0527 461.984 39.0527 429.712C39.0527 397.383 65.3448 371.104 97.6465 371.104H839.854C872.155 371.104 898.447 397.383 898.447 429.712C898.447 461.984 872.155 488.306 839.854 488.306H97.6465ZM371.104 566.411V527.329H449.209V566.411H371.104ZM332.021 566.411H274.801L248.765 527.329H332.021V566.411ZM488.291 527.329H566.396V566.411H488.291V527.329ZM605.479 527.329H688.735L662.699 566.411H605.479V527.329Z" />
      <path d="M234.375 410.142C223.588 410.142 214.834 418.896 214.834 429.712C214.834 440.469 223.588 449.224 234.375 449.224C245.162 449.224 253.916 440.469 253.916 429.712C253.916 418.898 245.162 410.142 234.375 410.142Z" />
      <path d="M390.615 410.142C379.829 410.142 371.104 418.896 371.104 429.712C371.104 440.469 379.83 449.224 390.615 449.224C401.4 449.224 410.156 440.469 410.156 429.712C410.156 418.898 401.402 410.142 390.615 410.142Z" />
      <path d="M546.885 410.142C536.098 410.142 527.344 418.896 527.344 429.712C527.344 440.469 536.098 449.224 546.885 449.224C557.672 449.224 566.396 440.469 566.396 429.712C566.396 418.898 557.67 410.142 546.885 410.142Z" />
      <path d="M703.125 410.142C692.338 410.142 683.584 418.896 683.584 429.712C683.584 440.469 692.338 449.224 703.125 449.224C713.912 449.224 722.666 440.469 722.666 429.712C722.666 418.898 713.912 410.142 703.125 410.142Z" />
    </svg>
  );
};
