/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { FeeModuleV0, FeeModuleV0Interface } from "../FeeModuleV0";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "pay",
    outputs: [
      {
        internalType: "bool",
        name: "success",
        type: "bool",
      },
    ],
    stateMutability: "pure",
    type: "function",
    constant: true,
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5060be8061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c8063c407687614602d575b600080fd5b604060383660046054565b600192915050565b604051901515815260200160405180910390f35b600080604083850312156065578182fd5b82356001600160a01b0381168114607a578283fd5b94602093909301359350505056fea26469706673582212207262cfe99d61eb6d4c83cec3c2d956c434421d883172f96f2efe053e533571b564736f6c63430008040033";

type FeeModuleV0ConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: FeeModuleV0ConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class FeeModuleV0__factory extends ContractFactory {
  constructor(...args: FeeModuleV0ConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<FeeModuleV0> {
    return super.deploy(overrides || {}) as Promise<FeeModuleV0>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): FeeModuleV0 {
    return super.attach(address) as FeeModuleV0;
  }
  connect(signer: Signer): FeeModuleV0__factory {
    return super.connect(signer) as FeeModuleV0__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): FeeModuleV0Interface {
    return new utils.Interface(_abi) as FeeModuleV0Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): FeeModuleV0 {
    return new Contract(address, _abi, signerOrProvider) as FeeModuleV0;
  }
}
